import React, { useEffect } from 'react';
import '../styles/PricingPage.css';
import CheckMark from '../assets/check-mark.png';  

const PricingPageBusinesses = () => {
  useEffect(() => {
    document.body.classList.add('pricing-page-body');
    return () => {
      document.body.classList.remove('pricing-page-body');
    };
  }, []);

  return (
    <div className="pricing-page">
      <header className="header-container">
      <nav className="nav">
        <a href="/signin">Sign In</a>
        <div className="dropdown">
          <a href="/pricing">Pricing</a>
          <div className="dropdown-content">
            <a href="/pricing/businesses">Businesses</a>
            <a href="/pricing/couples">Couples</a>
          </div>
        </div>
        <a href="/solutions">Solutions</a>
      </nav>
        <div className="header-text">
          <h1 className="logo"><a href="/">BestDay2Marry</a></h1>
        </div>
      </header>

      <div className="content-above-cards"> 
        <header className="pricing-header">
          <h1 className="pricing-tagline">Plans & Pricing</h1>
          <p className="pricing-subheading">Get Started for Free and Choose the Plan that Fits Your Needs.</p>
          <p className="pricing-description">Pick the Perfect Plan for Your Event. Upgrade or Adjust as Your Needs Evolve.</p>

          <div className="email-trial">
            <input type="email" placeholder="Enter your email address" />
            <button className="trial-button">Request a trial</button>
          </div>
          <p className="no-credit-info">
            Try BestDay2Marry free, no credit card required. By entering your email, you agree to receive marketing emails from BestDay2Marry.
          </p>
        </header>
      </div>

      <div className="pricing-cards-container">
        <header className="plans-header">
          <h2>Business Plans</h2>
        </header>

        <div className="pricing-cards">
          <div className="pricing-card">
            <h3 className="plan-title">Trial</h3>
            <p className="price">Free</p>
            {/* <p className="access-type">One-time Access:</p> */}
            <div className="card-divider"></div> 
            <ul className="features-list">
              <li><img src={CheckMark} alt="Check" />1 week access</li>
              <li><img src={CheckMark} alt="Check" />1 location</li>
              <li><img src={CheckMark} alt="Check" />1 date</li>
              <li><img src={CheckMark} alt="Check" />Temperature</li>
              <li><img src={CheckMark} alt="Check" />Humidity</li>
              <li><img src={CheckMark} alt="Check" />Wind</li>
              <li><img src={CheckMark} alt="Check" />Clouds</li>
              <li><img src={CheckMark} alt="Check" />Precipitation</li>
              <li><img src={CheckMark} alt="Check" />Sunrise/Sunset</li>
            </ul>
            <button className="cta-button">Try for free</button>
          </div>

          <div className="pricing-card">
            <h3 className="plan-title">Standard</h3>
            <p className="price">$500 <span>/ month</span></p>
            <div className="card-divider"></div>
            <ul className="features-list">
              <li><img src={CheckMark} alt="Check" />1 location</li>
              <li><img src={CheckMark} alt="Check" />1 date</li>
              <li><img src={CheckMark} alt="Check" />Temperature</li>
              <li><img src={CheckMark} alt="Check" />Humidity</li>
              <li><img src={CheckMark} alt="Check" />Wind</li>
              <li><img src={CheckMark} alt="Check" />Clouds</li>
              <li><img src={CheckMark} alt="Check" />Precipitation</li>
              <li><img src={CheckMark} alt="Check" />Sunrise/Sunset</li>
            </ul>
            <button className="cta-button">Get started</button>
          </div>

          <div className="pricing-card">
            <h3 className="plan-title">Premium</h3>
            <p className="price">$700 <span>/ monthly</span></p>
            { /* <p className="save-info">You save $80</p> */ }
            { /* <div className="custom-card-divider"></div> */ }
            <div className="card-divider"></div>
            <ul className="features-list">
              <li><img src={CheckMark} alt="Check" />1 location</li>
              <li><img src={CheckMark} alt="Check" />1 date</li>
              <li><img src={CheckMark} alt="Check" />Temperature</li>
              <li><img src={CheckMark} alt="Check" />Humidity</li>
              <li><img src={CheckMark} alt="Check" />Wind</li>
              <li><img src={CheckMark} alt="Check" />Clouds</li>
              <li><img src={CheckMark} alt="Check" />Precipitation</li>
              <li><img src={CheckMark} alt="Check" />Sunrise/Sunset</li>
              <li><img src={CheckMark} alt="Check" />Extreme Weather: Fires, Flooding, Storms</li>
            </ul>
            <button className="cta-button">Get started</button>

          </div>

          <div className="pricing-card">
            <h3 className="plan-title">Custom</h3>
            <p className="price">Price varies <span>/ monthly</span></p>
            <div className="card-divider"></div>
            <ul className="features-list">
              <li><img src={CheckMark} alt="Check" />Choose locations</li>
              <li><img src={CheckMark} alt="Check" />Choose number of dates</li>
              <li><img src={CheckMark} alt="Check" />Temperature</li>
              <li><img src={CheckMark} alt="Check" />Humidity</li>
              <li><img src={CheckMark} alt="Check" />Wind</li>
              <li><img src={CheckMark} alt="Check" />Clouds</li>
              <li><img src={CheckMark} alt="Check" />Precipitation</li>
              <li><img src={CheckMark} alt="Check" />Sunrise/Sunset</li>
              <li><img src={CheckMark} alt="Check" />Extreme Weather: Fires, Flooding, Storms</li>
            </ul>
            <button className="cta-button">Call Us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPageBusinesses;
