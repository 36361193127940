import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LandingPage.css';
import heroImage from '../assets/test_hero_image.webp'; 
import img1 from '../assets/waves-1.png'; 
import img2 from '../assets/waves-2.png';
import img3 from '../assets/waves-3.png';
import img4 from '../assets/waves-4.png';
import img5 from '../assets/waves-5.png';
import heartImage from '../assets/heart.png'; 
import Logo from '../assets/Climformatics-logo.png';

import DashboardScreenshot from '../assets/header-screenshot.png'; 

import DashboardScreenshot1 from '../assets/dashboard-showcase-1.png';
import DashboardScreenshot2 from '../assets/dashboard-showcase-2.png';
import DashboardScreenshot3 from '../assets/dashboard-showcase-3.png';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const LandingPage = () => {
  const images = [img1, img2, img3, img4, img5];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const carouselImages = [DashboardScreenshot1, DashboardScreenshot2, DashboardScreenshot3];
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Component mounted');
    document.body.classList.add('override-body');
    console.log('Added override-body class to body');

    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 500); 

    const carouselInterval = setInterval(() => {
      setCurrentCarouselIndex((prevIndex) => (prevIndex + 1) % carouselImages.length);
    }, 3000); // Change every 3 seconds

    return () => {
      clearInterval(interval);
      clearInterval(carouselInterval);
      console.log('Component unmounted');
      document.body.classList.remove('override-body');
      console.log('Removed override-body class from body');
    };
  }, []);

  const handleContactSubmit = async (e) => {
    e.preventDefault();
  
    console.log('About to submit data', formData);

    try {
      const options = {
        method: 'POST',
        headers:{ 'content-type': 'application/json'},
        body: JSON.stringify({name: formData.name, email: formData.email, text: formData.message}),
      };
      const response = await fetch('https://junbispark.net/contact_form/', options);
      const result = await response.json();

      console.log('CONTACT FORM SUBMISSION RESULT', result);
      setIsSubmitted(true); 
    } catch (error) {
      console.log('Contact form submission failed with error:', error);
    }
  };

  const handleLearnMoreClick = () => {
    navigate('/solutions');  
  };

  return (
    <div className="landing-page">
      <section className="hero">
        <img src={heroImage} alt="Hero" className="hero-image" />
        <img src={heartImage} alt="Heart" className="heart-image" />
      </section>
      <header className="header-container">
      <nav className="nav">
        <a href="/signin">Sign In</a>
        <div className="dropdown">
          <a href="/pricing">Pricing</a>
          <div className="dropdown-content">
            <a href="/pricing/businesses">Businesses</a>
            <a href="/pricing/couples">Couples</a>
          </div>
        </div>
        <a href="/solutions">Solutions</a>
      </nav>
        <div className="header-text">
          <h1 className="logo"><a href="/">BestDay2Marry</a></h1>
          <h2 className="tagline">Climate insights for planning a dream wedding</h2>
        </div>
      </header>
      <section className="content-section">
        <div className="text-content">
          <h1 className="content-title">Plan weddings without the fear of sudden weather disruptions</h1>
          <p className="content-description">Gone are the days of unpredictable weather spoiling your wedding events. With BestDay2Marry, you will have access to cutting-edge climate forecasting 
            technology that predicts weather patterns with remarkable accuracy a year ahead.</p>

          <h1 className="content-title">Elevate your event planning</h1>
          <p className="content-description">For professionals and venue owners, BestDay2Marry is more than just a weather forecast-- it's a strategic partner in success. Our advanced climate
            technology forecasts weather a year in advance, enabling you to offer the perfect setting for any event. Whether it's securing an open-air venue on a clear day or preparing a stunning indoor
            alternative for unexpected rain, you can ensure every wedding goes without a hitch. Elevate your service and exceed expectations with every event planned under clear skies.
          </p>
          <h1 className="content-title">Cherish perfect moments</h1>
          <p className="content-description">Soon, for couples who dream of a sun-kissed beach wedding or a serene ceremony under clear lake skies, BestDay2Marry's services will ensure that you can select
             an idyllic day with confidence. Wave goodbye to worries of unpredictable weather spoiling your special moments. With our advanced forecasting technology, you'll have the luxury to choose a day 
             that promises sunshine and clear skies, making your wedding as magical as you've envisioned. Prepare to create unforgettable memories without the fear of weather disruptions.</p>
          <button className="primary-button" onClick={handleLearnMoreClick}>Learn more!</button>
        </div>
        <div className="image-content">
          <img src={carouselImages[currentCarouselIndex]} alt="Dashboard screenshot" />
        </div>
      </section>
      <section className="contact-section">
        <div className="contact-info-container">
          <h2 className="content-title">Contact Us</h2>
          <div className="address-phone-info">
            <p className="content-description" id="company-address">5381 Ridgewood Drive, Fremont, CA 94555</p>
            <p className="content-description" id="company-phone">Phone: 408-533-5617</p>
          </div>
          <p className="content-description" id="company-email" style={{ fontWeight: 'semi-bold' }}>info@bestday2marry.com</p>
          <div className="powered-by">
            <p className="content-description" id="powered-by-text">Powered by<img className="climformatics-logo" src={Logo} alt="Climformatics" /></p>
          </div>
          <div className="social-icons">
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-google"></i></a>
          </div>
        </div>
        <div className="contact-form">
          {!isSubmitted ? (
            <>
              <h2>Let's plan a dream wedding together.</h2>
              <input type="text" placeholder="Full Name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
              <input type="email" placeholder="Email Address" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
              <textarea placeholder="Message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
              <button className="primary-button" onClick={handleContactSubmit}>Submit</button>
            </>
          ) : (
            <p>Thank you for contacting us! We will get back to you soon.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default LandingPage;